export default [
  {
    header: 'Settings',
  },
  {
    title: 'Register User',
    route: 'register-user',
    icon: 'UserPlusIcon',
    resource: 'Register',
  },
  {
    title: 'Permissions',
    route: 'permissions',
    icon: 'ShieldIcon',
    action: 'read',
    resource: 'Permissions',
    // },
  },
  {
    title: 'Account Settings',
    route: 'settings-account-setting',
    icon: 'UsersIcon',
  },

  // {
  //   title: 'Menu Levels',
  //   icon: 'MenuIcon',
  //   children: [
  //     {
  //       title: 'Menu Level 2.1',
  //       route: null,
  //     },
  //     {
  //       title: 'Menu Level 2.2',
  //       children: [
  //         {
  //           title: 'Menu Level 3.1',
  //           route: null,
  //         },
  //         {
  //           title: 'Menu Level 3.2',
  //           route: null,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   header: 'Help',
  // },
  // {
  //   title: 'FAQs',
  //   route: 'pages-faq',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'Knowledge Base',
  //   route: 'pages-knowledge-base',
  //   icon: 'BookOpenIcon',
  // },
  // {
  //   title: 'Support',
  //   route: 'support',
  //   icon: 'LifeBuoyIcon',
  // },
]

import { watch } from '@vue/composition-api'
import mapBoxGeocoder from '@mapbox/mapbox-sdk/services/geocoding'

const geocodingClient = mapBoxGeocoder({ accessToken: 'pk.eyJ1IjoiYXhzaiIsImEiOiJja3N6anp1NTMwOWZ1MnhzNWFyNW13MDduIn0.5xohA-1pZu3eCPbIBsPPdA' })

export default function useAutoSuggest(props, filteredData, searchQuery) {
  /**
   * Filter group against provided query
   * Grp Structure:
   * {
   *    key: 'title',
   *    data: [
   *      title: 'Admin', img: 'someImage.png',
   *      title: 'Template', img: 'otherImage.png',
   *    ]
   * }
   * @param {Object} grp Group object to perform filter on
   * @param {String} query Query string to filter
   */
  const filterGrp = (grp, query) => {
    const exactEle = grp.data.filter(item => item[grp.key].toLowerCase().startsWith(query.toLowerCase()))
    const containEle = grp.data.filter(

      item => !item[grp.key].toLowerCase().startsWith(query.toLowerCase()) && item[grp.key].toLowerCase().indexOf(query.toLowerCase()) > -1,
    )
    return exactEle.concat(containEle).slice(0, props.searchLimit)
  }

  const handleSearchQueryUpdate = async function handleSearchQueryUpdate(val) {
    if (val === '') {
      // eslint-disable-next-line no-param-reassign
      filteredData.value = {}
    } else {
      const queriedData = {}

      const dataGrps = Object.keys(props.data)

      dataGrps.forEach((grp, i) => {
        queriedData[dataGrps[i]] = filterGrp(props.data[grp], val)
      })

      /*
      Here I am calling the Mapbox API and injecting that data into the filteredData variable that is used
      in the vue component in search bar to update the results.
      In search-and-Bookmark-data.js i included another sections specifically for places, and that's where in inject the response
      that comes back from MapBox API.
      */
      // Places search
      const response = await geocodingClient.forwardGeocode({
        query: val,
        limit: 2,
        countries: ['AU'], // Enable to only have Map box API return Australian locations.
        types: ['region', 'postcode', 'district', 'place', 'locality'],
      }).send()
      if (response) {
        const placesData = response.body
        queriedData.places = placesData.features
      }
      // For some reason queried data some be put into filtered data last for the selected index to work.
      // eslint-disable-next-line no-param-reassign
      filteredData.value = queriedData
    }
  }

  watch(searchQuery, val => handleSearchQueryUpdate(val))
}
